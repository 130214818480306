.useful-links-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .useful-links-container h1 {
    font-size: 24px;
    color: #333;
  }
  
  .useful-links-container ul {
    list-style-type: none;
    padding: 0;
  }
  
  .useful-links-container li {
    margin-bottom: 10px;
  }
  
  .useful-links-container a {
    color: #007bff;
    text-decoration: none;
  }
  
  .useful-links-container a:hover {
    text-decoration: underline;
  }
  