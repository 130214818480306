.serviceBox {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9fafc;
  border-radius: 10px;
}

.serviceBox h2 {
  font-size: 32px;
  margin-bottom: 20px;
  color: #244c59;
}

.services-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

/* src/services.css */
.service-box h3 a {
  text-decoration: none;
  color: inherit; /* Ensures the link color inherits from the surrounding text */
}

.service-box h3 a:hover {
  text-decoration: underline; /* Optional: adds underline on hover */
}

.service-box {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px 4px 15px #244c59;
  transition: transform 0.3s ease;
}

.service-box:hover {
  transform: translateY(-5px);
}

.service-box h3 {
  font-size: 22px;
  margin-bottom: 10px;
  color: #244c59;
}

.service-box p {
  font-size: 16px;
  line-height: 1.6;
  color: #666666;
}

.adviser-container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
}

.adviser-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.adviser-description {
  flex: 1;
}

.adviser-description h3 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333333;
}

.adviser-description p {
  font-size: 16px;
  line-height: 1.6;
  color: #666666;
}

/* Media Queries for responsiveness */

@media screen and (max-width: 1024px) {
  .serviceBox h2 {
    font-size: 28px;
  }

  .service-box h3 {
    font-size: 20px;
  }

  .service-box p {
    font-size: 15px;
  }

  .adviser-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .adviser-image {
    width: 120px;
    height: 120px;
  }

  .adviser-description h3 {
    font-size: 22px;
  }

  .adviser-description p {
    font-size: 15px;
  }
}

@media screen and (max-width: 768px) {
  .services-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .serviceBox h2 {
    font-size: 24px;
  }

  .service-box h3 {
    font-size: 18px;
  }

  .service-box p {
    font-size: 14px;
  }

  .adviser-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .adviser-image {
    width: 100px;
    height: 100px;
  }

  .adviser-description h3 {
    font-size: 20px;
  }

  .adviser-description p {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .serviceBox {
    padding: 10px;
  }

  .serviceBox h2 {
    font-size: 20px;
  }

  .services-container {
    grid-template-columns: 1fr;
  }

  .service-box {
    padding: 15px;
  }

  .service-box h3 {
    font-size: 16px;
  }

  .service-box p {
    font-size: 13px;
  }

  .adviser-image {
    width: 80px;
    height: 80px;
  }

  .adviser-description h3 {
    font-size: 18px;
  }

  .adviser-description p {
    font-size: 13px;
  }
  
}
