.contact-form-section {
  padding: 20px;
  background-color: #f9f9f9;
  margin-bottom: 25px;
}

.container-headh2 {
  max-width: 800px;
  margin: auto;
  padding: 0 15px;
}

.contact-form-content {
  background: white;
  padding: 50px;
  border-radius: 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 5vh;
}

.container-headh2 h1 {
  text-align: center;
  font-size: 32px;
  margin-bottom: 1.5em;
  color: #244c59;
}

.contact-form-group {
  margin-bottom: 15px;
}

input, textarea {
  width: 100%;
  padding: 10px;
  margin: 5px 0 0 0;
  border-radius: 3px;
  border: 1px solid #ccc;
  font-size: 16px;
}

input.error, textarea.error {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 0.875em;
  margin-top: 5px;
}

.submit-button {
  background-color: #244c59;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 3px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #1d3a47;
}

.popup-message.success {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid green;
  background-color: #d4edda;
  color: green;
  border-radius: 3px;
  text-align: center;
}

.map-container {
  margin-top: 20px;
}

.map-container iframe {
  width: 100%;
  height: 450px;
  border: 0;
}

/* Responsive styles */
@media (max-width: 768px) {
  .contact-form-content {
    padding: 30px;
  }

  .container-headh2 h1 {
    font-size: 28px;
  }

  input, textarea {
    font-size: 14px;
  }

  .submit-button {
    font-size: 14px;
    padding: 10px 15px;
  }

  .map-container iframe {
    height: 350px;
  }
}

@media (max-width: 480px) {
  .contact-form-content {
    padding: 20px;
    
  }

  .container-headh2 h1 {
    font-size: 24px;
  }

  input, textarea {
    font-size: 14px;
    padding: 8px;
  }

  .submit-button {
    font-size: 14px;
    padding: 8px 10px;
  }

  .map-container iframe {
    height: 300px;
  }
}
