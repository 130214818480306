/* src/components/footer.css */
.footer {
  background-color: #244c59;
  color: #f2e5bb;
  padding: 25px;
  text-align: center;
  border-radius: 25px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  flex-wrap: wrap;
}

.footer-left {
  flex: 1;
  text-align: left;
}

.footer-logo {
  max-width: 150px;
  margin-bottom: 10px;
  border-radius: 50%;
  background-color: #fff;
  justify-self: center;
}

.footer-lia {
  max-width: 100px;
  margin-bottom: 10px;
}
.footer-right {
  flex: 1;
  text-align: right;
}

.footer-right p {
  margin: 10px 0;
}

.social-icons {
  display: flex;
  justify-content: flex-end;
}

.social-icons a {
  color: #f2e5bb;
  font-size: 24px;
  margin-left: 10px;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #fff;
}

.footer-bottom {
  margin-top: 20px;
  border-top: 1px solid #f2e5bb;
  padding-top: 10px;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    text-align: center;
  }

  .footer-left,
  .footer-right {
    text-align: center;
  }

  .social-icons {
    justify-content: center;
  }
}
