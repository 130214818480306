/* src/pages/aewv.css */

.aewv-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.aewv-container h1, .aewv-container h2 {
  color: #2c3e50;
}

.aewv-container p {
  font-size: 16px;
  line-height: 1.6;
  color: #34495e;
}

.aewv-container ul {
  list-style-type: disc;
  padding-left: 20px;
  color: #34495e;
}

.aewv-container ul li {
  margin-bottom: 10px;
}

.aewv-container img.aewv-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 20px 0;
}

.aewv-container form {
  margin-top: 20px;
}

.aewv-container form div {
  margin-bottom: 15px;
}

.aewv-container form label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.aewv-container form input[type="text"],
.aewv-container form input[type="email"],
.aewv-container form input[type="file"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.aewv-container form button {
  padding: 10px 20px;
  background-color: #2980b9;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.aewv-container form button:hover {
  background-color: #3498db;
}
