.banner {
  height: 70vh;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  position: relative;
}

/* Background Images */
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  animation-duration: 30s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.background-image.first {
  background-image: url('./welcome.jpg');
  animation-name: fadeBackground;
}

.background-image.second {
  background-image: url('./welcome1.jpg');
  animation-name: fadeBackground2;
}

.background-image.third {
  background-image: url('./welcome2.jpg');
  animation-name: fadeBackground;
}

.background-image.fourth {
  background-image: url('./welcome3.jpg');
  animation-name: fadeBackground2;
}

/* Banner Content */
.banner-content {
  height: 20vh;
  text-align: center;
  padding: 20px;
}

.banner-content h1 {
  font-size: 2em;
  margin-bottom: 0.5em;
  color:  #244c59;;
}

.banner-content p {
  font-size: 1.2em;
  color: #666;
}

/* Keyframes */
@keyframes fadeBackground {
  0% { opacity: 1; }
  33% { opacity: 0; }
  66% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeBackground2 {
  0% { opacity: 0; }
  33% { opacity: 1; }
  66% { opacity: 1; }
  100% { opacity: 0; }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .banner {
    height: 50vh;
    border-radius: 10px;
  }
  
  .banner-content {
    height: 30vh;
    padding: 10px;
  }
  
  .banner-content h1 {
    font-size: 1.5em;
  }
  
  .banner-content p {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .banner {
    height: 40vh;
    border-radius: 5px;
  }
  
  .banner-content {
    height: 40vh;
    padding: 5px;
  }
  
  .banner-content h1 {
    font-size: 1.2em;
  }
  
  .banner-content p {
    font-size: 0.9em;
  }
  
}
