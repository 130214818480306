/* src/components/header.css */

:root {
  --primary-color: #244c59;
  --secondary-color: #333;
  --background-color: #f8f9fa;
  --hover-color: #0056b3;
  --white-color: #ffffff;
  --font-family: 'Montserrat', sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: var(--background-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: var(--font-family);
}

.logo img {
  width: 40vw; /* Adjust logo size based on viewport width */
  max-width: 20em; /* Set a maximum size */
  height: auto;
}


.nav {
  display: flex;
  align-items: center;
}

.menu-toggle {
  display: none; /* Hide toggle button by default for larger screens */
  cursor: pointer;
}

.menu-toggle .hamburger {
  width: 30px;
  height: 3px;
  background-color: var(--secondary-color);
  margin: 6px 0;
  transition: transform 0.3s ease-in-out;
}

.menu-toggle.open .hamburger {
  transform: rotate(45deg);
}

.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
}

.nav-item {
  position: relative;
  margin-left: 20px;
}

.nav-link {
  text-decoration: none;
  color: var(--secondary-color);
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  border-radius: 25px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-link:hover {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--white-color);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 1000;
  border-radius: 24px;
  overflow: hidden;
}

.dropdown-menu li {
  margin: 0;
  padding: 10;
  
}

.dropdown-menu li a {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: var(--secondary-color);
  white-space: nowrap;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dropdown-menu li a:hover {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.dropdown:hover .dropdown-menu {
  display: block;
}

@media screen and (max-width: 768px) {
  .nav-links {
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--background-color);
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    display: none;
    z-index: 999;
  }

  .nav-links.active {
    display: flex;
  }

  .nav-item {
    margin-left: 0;
  }

  .nav-link {
    padding: 10px 20px;
    width: 100%;
    text-align: left;
  }

  .dropdown-menu {
    position: static;
    box-shadow: none;
    border-radius: 0;
    display: block;
    background-color: var(--background-color);
    padding: 10px 0;
  }

  .dropdown-menu li a {
    padding: 10px 20px;
  }

  .menu-toggle {
    display: block;
  }

  .nav-links {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: var(--background-color);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 999;
  }

  .nav-links.active {
    transform: translateY(0);
  }

  .nav-item {
    margin: 20px 0;
  }

  .nav-link {
    padding: 20px;
  }

  .dropdown {
    position: relative;
  }

  .dropdown-menu {
    display: none;
    position: static;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
    background-color: transparent;
  }

  .dropdown-menu li a {
    padding: 10px 20px;
  }
}
